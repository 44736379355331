export default {
    state: {
        created: 0,
        iss: '',
        sub: '',
    },
    mutations: {

        setCreated(state, created) {
            state.created = created
        },
        setIss(state, iss) {
            state.iss = iss
        },
        setSub(state, sub) {
            state.sub = sub
        }
    },
    getters: {

    },
    actions: {
        // 获取用户相关信息
        getUserInfo({ state }) {
            window.sessionStorage.setItem('created',state.created);
            window.sessionStorage.setItem('iss',state.iss);
            window.sessionStorage.setItem('sub',state.sub);
        }
    }
}