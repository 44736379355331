import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
      path: '/login',
      name: 'login',
      component: () =>
          import ('@/views/login/login.vue'),
      meta: {
          title: '登录 - 腾泡报价小店商家中心'
      }
  },
  {
    path: '/forgetpassword',
      name: 'forgetpassword',
      component: () =>
          import ('@/views/forgetPwd.vue'),
      meta: {
          title: '忘记密码 - 腾泡报价小店商家中心'
      }
  },
  {
    path: '/home',
    name: 'home',
    component: () =>
        import ('@/views/home/home.vue'),
    meta: {
        title: '腾泡报价小店商家中心'
    },
    children: [
      {
        path: '/message',
        name: 'message',
        component: () => import(/* webpackChunkName: "about" */ '@/views/message/message.vue'),
        meta: { title: '首页 - 腾泡报价小店商家中心'}
      },
      {
        path: '/subuser',
        name: 'subuser',
        component: () => import(/* webpackChunkName: "about" */ '@/views/subuser/subuser.vue'),
        meta: { title: '子用户管理 - 腾泡报价小店商家中心'}
      },
      {
        path: '/goodsclassify',
        name: 'goodsclassify',
        component: () => import(/* webpackChunkName: "about" */ '@/views/goodsClassify/goodsClassify.vue'),
        meta: { title: '商品分类 - 腾泡报价小店商家中心'}
      },
      {
        path: '/goods',
        name: 'goods',
        component: () => import(/* webpackChunkName: "about" */ '@/views/goods/goods.vue'),
        meta: { title: '商品 - 腾泡报价小店商家中心'},
      },
      {
        path: '/quotation',
        name: 'quotation',
        component: () => import(/* webpackChunkName: "about" */ '@/views/quotation/quotation.vue'),
        meta: { title: '报价小店 - 腾泡报价小店商家中心'},
      },
      {
        path: '/goods/addgoods',
        name: 'addGoods',
        component: () => import(/* webpackChunkName: "about" */ '@/views/goods/addGoods.vue'),
        meta: { title: '添加商品 - 腾泡报价小店商家中心'}
      },
      {
        path: '/goods/editgoods',
        name: 'editGoods',
        component: () => import(/* webpackChunkName: "about" */ '@/views/goods/editGoods.vue'),
        meta: { title: '编辑商品 - 腾泡报价小店商家中心'}
      },
    ]
},
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数，表示放行   next('/login')表示强制跳转到登录页

  //如果跳转的页面为登录页，则直接放行
  const tokenStr = window.sessionStorage.getItem('token');
  if (to.path === '/login') {
      if (to.meta.title) {
          document.title = to.meta.title;
      }
      if(!tokenStr){
        return next();
      }else{
        return next('/message')
      }
  }
  if (to.path === '/forgetpassword') {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    return next();
 }
  //获取token值
  // console.log(tokenStr);
  //如果token不为空则放行，如果为空则跳转到登陆页
  if (to.meta.title) {
      document.title = to.meta.title;
  }
  if (!tokenStr) {
      return next('/login');
  }
  next();

})
export default router
